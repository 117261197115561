import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import pt_BR from "../locale/pt_BR.json";
import safira_pt_BR from "../safira-app/locale/pt_BR.json";

import en_US from "../locale/en_US.json";
import safira_en_US from "../safira-app/locale/en_US.json";

import Cookies from "js-cookie";
import { decode } from "safira-app/utils/crypto";
import { IUser } from "safira-app/interfaces/User";

const resources = {
  pt: {
    translation: {
      ...pt_BR,
      ...safira_pt_BR,
    },
  },
  "pt-BR": {
    translation: {
      ...pt_BR,
      ...safira_pt_BR,
    },
  },
  en: {
    translation: {
      ...en_US,
      ...safira_en_US,
    },
  },
  "en-US": {
    translation: {
      ...en_US,
      ...safira_en_US,
    },
  },
};

const supportedLanguages = ["pt-BR", "en-US", "en", "pt"];
const encodedUser = decode(Cookies.get("user") ?? "");
const { config }: IUser = encodedUser ? JSON.parse(encodedUser) : { config: { default_language: "en" } };

const defineLanguage = supportedLanguages.includes(config.default_language) ? config.default_language : "en";

i18n.use(initReactI18next).init({
  resources,
  lng: defineLanguage,
});
