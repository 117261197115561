import React, { useEffect, useContext, useState } from 'react';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import Tracker from '@openreplay/tracker';
import { Settings } from 'luxon';

import { useInterceptor } from 'hooks/useInterceptor';
import { useAuth } from 'safira-app/hooks/useAuth';
import { links } from 'safira-app/config/links';
import VacationsPage from 'pages/Vacations';
import EvaluationPage from 'pages/Evaluation';
import { ProfileContext } from 'contexts/ProfileContext';
import { usePermissions } from 'safira-app/contexts/Permissions';
import OccurrencesPage from 'pages/Occurrences';
import InCheck from 'pages/InCheck';

const isProduction = links.production!;

const CreateRoutes: React.FC<unknown> = () => {
  const { signOut, refreshToken, user } = useAuth();
  const { profile } = useContext(ProfileContext);
  const { companyId, checkPermission, requestFinished } = usePermissions();
  const [redirect, setRedirect] = useState<string | null>(null);

  const sessionInfo = sessionStorage.getItem('openreplay-session');

  Settings.defaultLocale = user.config.default_language;
  Settings.defaultZone = user.config.default_timezone;

  useEffect(() => {
    if (!!isProduction && user && !sessionInfo) {
      const tracker = new Tracker({
        projectKey: 'Jn5iTG5DsiZwMofN6xNO',
        ingestPoint: 'https://logging.incicle.com/ingest',
        obscureInputEmails: false,
        obscureTextEmails: false,
        defaultInputMode: 0,
        network: {
          captureInIframes: false,
          capturePayload: true,
          failuresOnly: false,
          ignoreHeaders: false,
          sessionTokenHeader: false,
        },
      });

      tracker.start({
        userID: user.email,
        metadata: {
          module: 'Painel do Gestor',
          profileID: user.profile_id,
          userType: user.type,
          username: user.username,
        },
      });

      localStorage.setItem('openreplay-session', 'created');
    }
  }, []); // eslint-disable-line

  useInterceptor(signOut, refreshToken);

  useEffect(() => {
    if (!profile) return;
    const selectedCompany = profile.companies.find(company => company.id === companyId);

    if (!selectedCompany) return;
    const hasAuthorization = Boolean(
      user.type === 'PERSON' &&
        (selectedCompany.is_manager_competence ||
          selectedCompany?.is_manager_in_check ||
          checkPermission(['managers_vacations_list']) ||
          checkPermission(['managers_list_occurrences'])),
    );

    if (requestFinished && !hasAuthorization) {
      window.location.href = links.web.social + '/404';
      return;
    }

    if (selectedCompany.is_manager_competence) {
      setRedirect('/evaluation');
    } else if (checkPermission(['managers_vacations_list'])) {
      setRedirect('/vacations');
    } else if (selectedCompany?.is_manager_in_check) {
      setRedirect('/in_check');
    } else {
      setRedirect('/occurrences');
    }
  }, [user.type, profile, companyId, checkPermission]); // eslint-disable-line

  if (!redirect) return <></>;

  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Navigate to={redirect} />} />
        <Route path="/vacations" element={<VacationsPage />} />
        <Route path="/evaluation" element={<EvaluationPage />} />
        <Route path="/in_check" element={<InCheck />} />
        <Route path="/occurrences" element={<OccurrencesPage />} />
      </Routes>
    </HashRouter>
  );
};

export default CreateRoutes;
